<template>
    <div class="home">
        <div class="d-flex align-center home-header">
            <p class="ma-0 text-h5 primary--text font-weight-bold">{{$t('scenarios')}}</p>
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" @click="$router.push({name: 'AddScenario'})" outlined icon><v-icon>mdi-plus</v-icon></v-btn> -->
        </div>

        <div ref="scenarios-wrapper" class="scenarios-wrapper" :style="{gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`}">
            <v-card width="100%" class="mx-2 pa-3 mt-6 rounded-t-xl rounded-b-0 d-flex flex-column  align-center justify-center scenario-card add-campaign-card" @click="$router.push({name: 'AddScenario'})">
                <v-btn color="primary" outlined icon large><v-icon>mdi-plus</v-icon></v-btn>
                <p class="my-2 text-h6 text-center primary--text font-weight-bold">{{$t("scenariosAddScenarioLabel")}}</p>
            </v-card>
            <v-card v-for="scenario in scenarios" :key="scenario.id" width="100%" class="pa-3 mt-4 rounded-t-xl rounded-b-0 scenario-card">
                <div class="my-2 d-flex align-center">
                    <v-spacer></v-spacer>
                    <v-btn @click="$router.push({name: 'Screens', query: {'scenarioId': scenario.id}})" x-small icon >
                        <v-icon small>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-divider class="mx-2" vertical v-if="isNull(scenario.screens)"></v-divider>
                    <v-btn x-small icon @click="removeScenario(scenario)" v-if="isNull(scenario.screens)">
                        <v-icon small>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
                <v-card-title class="pa-0 text-h6 font-weight-bold">{{scenario.name}}</v-card-title>
                <v-card-text class="pa-0 tex-caption d-flex flex-column">
                    <span>{{$t('creationDate', {date: formatDateAndHourEnToDateLocale(scenario.creationDate)})}}</span>
                    <span>{{$t('nbScreens', {nbScreens: scenario.screens.length})}}</span>
                    <div class="d-flex align-center">

                        <span>{{$t('nbCampaigns', {nbCampaigns: scenario.campaigns.length})}}</span>
                        <v-tooltip v-if="scenario.campaigns.length > 0" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="ml-1" v-on="on" v-bind="attrs" small>mdi-information</v-icon>
                            </template>
                            <ul class="d-flex flex-column">
                                <li v-for="(campaign, key) in scenario.campaigns" :key="`campaign-tooltip-${key}`">{{campaign.name}}</li>
                            </ul>
                        </v-tooltip>
                        <v-icon v-else class="ml-1" small>mdi-information</v-icon>
                    </div>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
const ScenarioService = require("@/services/ScenarioService.js")
const CampaignService = require("@/services/CampaignService.js")

export default {
    name: 'Scenarios',
    data: () => {
        return {
            scenarios: [],
            numberOfColumns: 0
        }
    },
    async created() {
        this.updatePageInfo(this.$route.meta)
        await this.getScenarios()
        this.scenarios.map(async scenario => {
            return Object.assign(scenario, {campaigns : await this.getCampaings(scenario.id) })
        })
    },
    mounted () {
        let wrapperWidth = this.$refs["scenarios-wrapper"].offsetWidth;
        this.numberOfColumns = parseInt(wrapperWidth / 300) - 1
    },
    methods: {
        getScenarios(){
            return new Promise(resolve => {
                ScenarioService.getAllScenarios().then(({status, data}) => {
                    if(status === 200 && this.isNotNull(data)){
                        this.scenarios = data.map(item => Object.assign(item, {campaigns : [] }) )
                        resolve()
                    }
                })
            })
        },
        getCampaings(scenarioId){
            return new Promise(resolve => {
                CampaignService.getCampaignsByScenario(scenarioId).then(({status, data}) => {
                    if(status === 200){
                        resolve(data || [])
                    }
                })
            })
        },
        removeScenario(scenario){
            if (window.confirm(this.$t("deleteScenarioConfirmation", {scenarioName: scenario.name}))) {
                ScenarioService.remove(scenario.id).then(({status, data}) => {
                    if(status === 200 && !data.error){
                        this.getScenarios()
                    }
                })
            }
        },
    },
}
</script>
<style>
    .scenario-card{
        border-bottom: solid 2px var(--primary-color) !important;
        border-color: var(--primary-color) !important;
    }
    .scenarios-wrapper{
        display: grid;
        grid-gap: 16px;
        justify-items: center;
    }
    .add-scenario-card{
        cursor: pointer;
    }
</style>
